import ApiService from "../services/api.service";

const prefix = "account";
const listPhone = "list-phone";
export default {
  getListPhone(params) {
    return ApiService.get(prefix, listPhone, params);
  },
  getListAccountEnterprise(params) {
    return ApiService.get(prefix + "/enterprise", "", params);
  },
  getListAccountEnterpriseDetail(params) {
    return ApiService.get(prefix + "/enterprise/detail", "", params);
  },
  exportData(params) {
    return ApiService.post(prefix + "/export", params);
  },
  exportDataList(params) {
    return ApiService.post(prefix + "/export-list", params);
  },
};
